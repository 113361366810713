.home-page {
  // 查询首页
  .main-form {
    max-width: 600px;
    width: 80%;
    height: 485px;
    margin: 0px auto;
    padding: auto;
    margin-top: 150px;

    .logo {
      margin: 0px auto;
      text-align: center;
      max-width: 400px;
      max-height: 230px;
      position: relative;
      width: 100%;

      .logo-img {
        width: 100%;
        height: auto;
        border-radius: 20px 50px;
      }
    }

    .input {
      margin-top: 60px;
    }

    .btn {
      margin-top: 24px;
      text-align: center;

      .btn-wrap {
        width: 120px;
      }
    }
  }

  // 查询结果页面
  .seach-result {
    .header {
      padding:16px 24px;
      background-color: #f6f6f6;
      float: left;
      width: 100%;

      .res-logo {
        height: 32px;
        cursor: pointer;
      }

      .res-input{
        width: 620px;
        margin-left: 16px;

      }

      .res-btn {
        width: 120px;
        margin-left: 16px;
      }
    }

    .res-content {
      float: left;
      width: 100%;

      .list-wrap {
        width: 48%;
        padding-left: 88px;

        .loading-wrap {
          width: 100%;
          margin-top: 24px;
        }

        .tag-wrap {
          font-size: 12px;
          border-radius: 6px;
        }
      }
    }
  }
}