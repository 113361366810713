@import '~antd/dist/antd.css';

// 主题色
@primary-color: rgb(158, 208, 209);
@primary-color-1: tint(rgb(158, 208, 209), 10%); // 更浅色
@primary-color-2: shade(rgb(158, 208, 209), 10%); // 更深色

@danger-color: rgb(223, 68, 72); // 基础红色
@warning-color: rgb(239, 164, 58); //基础橙色
@blue-color: rgb(108, 191, 237); //基础橙色

// 基础文字色
@primary-text-color: #222;
@primary-text-active-color: #b04f08;

@primary-text-color-1: #666; // 更浅色的text
@primary-text-color-2: #999; // 更浅色的text

// 基础样式
body {
  color: @primary-text-color; // 基础文字色
}

// 公共清除浮动
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}